import React, {useEffect, createContext, useState, useCallback, memo} from 'react';
import i18next, {InitOptions} from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next, TFunction} from 'react-i18next';
import {i18nSettings} from './utils';

interface ProvidedValue {
  currentLanguage?: string;
  setLanguage: (language: string) => void;
  t: TFunction;
}

const Context = createContext<ProvidedValue>({
  currentLanguage: undefined,
  setLanguage: () => {
    console.log('TranslationsProvider is not rendered!');
  },
  t: () => null,
});

interface Props {
  params: Partial<InitOptions>;
  children?: React.ReactNode;
}

export const TranslationsProvider = memo((
  props: Props,
) => {

  const [initialized, setInitialized] = useState<boolean>(false);
  const [language, setLanguage] = useState<string | undefined>(props.params.lng);

  useEffect(() => {
    (async () => {
      // Initialize i18next
      const settings = i18nSettings(props.params);
      await i18next.use(LanguageDetector).use(initReactI18next).init(settings);
      if (!props.params.lng) {
        setLanguage(i18next.language);
      }
      setInitialized(true);
    })();
  }, [props.params]);

  const handleSetLanguage = useCallback(async (language) => {
    await i18next.changeLanguage(language);
    setLanguage(language);
  }, [setLanguage]);

  const MemoizedValue = React.useMemo(() => {
    const value: ProvidedValue = {
      currentLanguage: language,
      setLanguage: handleSetLanguage,
      t: i18next.t.bind(i18next),
    };

    return value;
  }, [language, handleSetLanguage]);

  return <Context.Provider value={MemoizedValue}>{initialized && props.children}</Context.Provider>;
});

export const useTranslations = (): ProvidedValue => React.useContext(Context);
