import {InitOptions} from 'i18next';

const detectionOptions = {
  // order and from where user language should be detected
  order: ['navigator'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  //cookieMinutes: 10,
  //cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' }
}

/**
 * @brief Function that return localization settings with default initialization
 * @param {InitOptions} param0
 */
export const i18nSettings = ({
  debug = false,
  resources = {},
  fallbackLng,
  detection = detectionOptions,
  ...rest
}: Partial<InitOptions & {detection: any}>): Partial<InitOptions> => ({
  debug,
  resources,
  fallbackLng: fallbackLng || Object.keys(resources)[0],
  detection,
  ...rest,
});
